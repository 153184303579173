import { defineMessages } from 'react-intl'

export default defineMessages({
  activityLog: {
    id: 'modules.SideBar.activityLog',
    defaultMessage: 'ACTIVITY LOG',
  },
  shipment: {
    id: 'modules.SideBar.shipment',
    defaultMessage: 'Shipments',
  },
  batch: {
    id: 'modules.SideBar.batch',
    defaultMessage: 'Batches',
  },
  order: {
    id: 'modules.SideBar.order',
    defaultMessage: 'Orders',
  },
  orderItem: {
    id: 'modules.SideBar.orderItem',
    defaultMessage: 'Items',
  },
  product: {
    id: 'modules.SideBar.product',
    defaultMessage: 'Products',
  },
  network: {
    id: 'modules.SideBar.network',
    defaultMessage: 'Network',
  },
  partner: {
    id: 'modules.SideBar.partner',
    defaultMessage: 'Partners',
  },
  user: {
    id: 'modules.SideBar.user',
    defaultMessage: 'Staff',
  },
  warehouse: {
    id: 'modules.SideBar.warehouse',
    defaultMessage: 'Warehouses',
  },
  container: {
    id: 'modules.SideBar.container',
    defaultMessage: 'Containers',
  },
  templates: {
    id: 'modules.SideBar.templates',
    defaultMessage: 'Templates',
  },
  tags: {
    id: 'modules.SideBar.tags',
    defaultMessage: 'Tags',
  },
  metadata: {
    id: 'modules.SideBar.metadata',
    defaultMessage: 'Custom Fields',
  },
  table: {
    id: 'modules.SideBar.table',
    defaultMessage: 'Table',
  },
  newTable: {
    id: 'modules.SideBar.newTable',
    defaultMessage: 'ZenGrid (Table)',
  },
  documents: {
    id: 'modules.SideBar.documents',
    defaultMessage: 'Documents',
  },
  cards: {
    id: 'modules.SideBar.cards',
    defaultMessage: 'Cards',
  },
  map: {
    id: 'modules.SideBar.map',
    defaultMessage: 'Map',
  },
  folder: {
    id: 'modules.SideBar.folder',
    defaultMessage: 'Folder',
  },
})
